import get from 'lodash/get';
import isArray from 'lodash/isArray';
import noop from 'lodash/noop';
import vars from '../../../common/vars';

export const yahooPixelLoadNameSpace = 'dotq';
export const yahooPixelAsyncCallback = 'dotq-tiasynccb';

const yahooPixelProjectId = vars.get('YAHOO_PIXEL_PROJECT_ID');
const yahooPixelId = vars.get('YAHOO_PIXEL_ID');

const goPremiumPageTrackingId = 'BetaLandingPageView';
const signUpPageTrackingId = 'BetaSignupPageView';
const subscriptionPageTrackingId = 'BetaCreditCardInputPageView';
const optInConfirmationTrackingId = 'BetaOptInConfirmationPageView';

// The code here actually stems from the vendor code (contained in https://jira.tunein.com/browse/TUNE-8660)
function trackingRequestCallback() {
  try {
    const previousTracking = window[yahooPixelLoadNameSpace];
    const trackingFunction = window.YAHOO.ywa.I13N.fireBeacon;
    window[yahooPixelLoadNameSpace] = [];
    window[yahooPixelLoadNameSpace].push = (p) => {
      trackingFunction([p]);
    };

    trackingFunction(previousTracking);
  } catch (e) {
    noop();
  }
}

// This also (partly) stems from the same vendor code
function performTrackingRequest(trackingId) {
  window[yahooPixelLoadNameSpace] = window[yahooPixelLoadNameSpace] || [];
  window[yahooPixelLoadNameSpace].push({
    projectId: yahooPixelProjectId,
    properties: {
      pixelId: yahooPixelId,
      qstrings: {
        ea: trackingId,
      },
    },
  });

  if (get(window, 'YAHOO.ywa.I13N.fireBeacon')) {
    trackingRequestCallback();
  } else {
    window[yahooPixelAsyncCallback] = window[yahooPixelAsyncCallback] || [];
    window[yahooPixelAsyncCallback].push(trackingRequestCallback);
  }
}

export function doAsyncCallback() {
  if (isArray(window[yahooPixelAsyncCallback])) {
    window[yahooPixelAsyncCallback].forEach((callback) => callback());

    delete window[yahooPixelAsyncCallback];
  }
}

export function trackGoPremiumLandingPage() {
  performTrackingRequest(goPremiumPageTrackingId);
}

export function trackSignUpPage() {
  performTrackingRequest(signUpPageTrackingId);
}

export function trackSubscriptionPage() {
  performTrackingRequest(subscriptionPageTrackingId);
}

export function trackOptInConfirmationPage() {
  performTrackingRequest(optInConfirmationTrackingId);
}
