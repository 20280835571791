import * as Sentry from '@sentry/browser';
import { ExtraErrorData } from '@sentry/integrations';
import {
  sentryClientDsn,
  sentryConfig,
} from 'src/common/constants/analytics/sentry';
import shouldLogToSentry from '../../common/utils/shouldLogToSentry';
import vars from '../../common/vars';

if (shouldLogToSentry()) {
  const options = {
    ...sentryConfig,
    integrations: [new ExtraErrorData(), new Sentry.BrowserTracing()],
    tracePropagationTargets: [
      /^https?:\/\/localhost/,
      new RegExp(`^https?://${vars.get('GEMINI_ORIGIN')}`),
      /^\//,
    ],
  };

  Sentry.init({
    dsn: sentryClientDsn,
    ...options,
  });
}
