import isArray from 'lodash/isArray';
import vars from '../../../common/vars';

export const ampBingLoadNameSpace = 'uetq';
export const ampBingAsyncCallback = 'uetq-tiasynccb';

const goPremiumPageTrackingId = vars.get('AMP_BING_GO_PREMIUM_ID');
const signUpPageTrackingId = vars.get('AMP_BING_SIGN_UP_ID');
const subscriptionPageTrackingId = vars.get('AMP_BING_SUBSCRIPTION_ID');
const optInConfirmationTrackingId = vars.get('AMP_BING_OPT_IN_CONFIRMATION_ID');

// The code here actually stems from the vendor code (contained in https://jira.tunein.com/browse/TUNE-8660)
function trackingRequestCallback(trackingId) {
  const trackingDefinition = { ti: trackingId };
  trackingDefinition.q = window[ampBingLoadNameSpace]; // eslint-disable-line no-param-reassign
  window[ampBingLoadNameSpace] = new window.UET(trackingDefinition);
  window[ampBingLoadNameSpace].push('pageLoad');
}

function performTrackingRequest(trackingDefinition) {
  if (window.UET) {
    trackingRequestCallback(trackingDefinition);
  } else {
    window[ampBingAsyncCallback] = window[ampBingAsyncCallback] || [];
    window[ampBingAsyncCallback].push(() =>
      trackingRequestCallback(trackingDefinition),
    );
  }
}

export function doAsyncCallback() {
  if (isArray(window[ampBingAsyncCallback])) {
    window[ampBingAsyncCallback].forEach((callback) => callback());

    delete window[ampBingAsyncCallback];
  }
}

export function trackGoPremiumLandingPage() {
  performTrackingRequest(goPremiumPageTrackingId);
}

export function trackSignUpPage() {
  performTrackingRequest(signUpPageTrackingId);
}

export function trackSubscriptionPage() {
  performTrackingRequest(subscriptionPageTrackingId);
}

export function trackOptInConfirmationPage() {
  performTrackingRequest(optInConfirmationTrackingId);
}
