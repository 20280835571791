import isServer from '../../../common/utils/isServer';
import keyMirror from '../../../common/utils/keyMirror';
import vars from '../../../common/vars';

export const fbPixelStandardEvents = keyMirror(
  'AddPaymentInfo',
  'AddToCart',
  'AddToWishlist',
  'CompleteRegistration',
  'Contact',
  'CustomizeProduct',
  'Donate',
  'FindLocation',
  'InitiateCheckout',
  'Lead',
  'PageView',
  'Purchase',
  'Schedule',
  'Search',
  'StartTrial',
  'SubmitApplication',
  'Subscribe',
  'ViewContent',
);

export const fbPixelCustomEvents = keyMirror(
  'SignIn',
  'SignUpWithFacebook',
  'SignUpWithGoogle',
  'SignUpWithApple',
  'SignUpPageVisit',
  'SignInPageVisit',
  'PaymentPageVisit',
  'RenewSubscription',
  'OptInComplete',
  'StartPlay',
);

export function fbTrack(evtName, details) {
  if (isServer()) {
    return;
  }

  const trackType = fbPixelStandardEvents[evtName] ? 'track' : 'trackCustom';
  window.fbq?.(trackType, evtName, details);
}

export function grantFacebookPixelConsent() {
  window.fbq?.('consent', 'grant');
}

export function fbTrackSignIn() {
  fbTrack(fbPixelCustomEvents.SignIn);
}

export function fbTrackPageView(path) {
  fbTrack(`PageView_${path}`);
}

export function fbTrackOauthLogin(isSignIn, isSignUp, eventName) {
  if (isSignUp) {
    fbTrack(eventName);
  } else if (isSignIn) {
    fbTrackSignIn();
  }
}

export function initFacebookPixel(gdprApplies, isOptedOutOfTargeting) {
  if (!window.fbq) {
    return;
  }

  // disablePushState = true is not recommended, disables logging on history.pushstate event
  // page views are instead logged by history.listen in src/client/index.js
  window.fbq.disablePushState = true;

  if (gdprApplies && isOptedOutOfTargeting) {
    window.fbq('consent', 'revoke'); // revoke GDPR consent by default
  }

  if (!gdprApplies && isOptedOutOfTargeting) {
    window.fbq('dataProcessingOptions', ['LDU'], 0, 0); // Enable Limited Data Use via geolocation for CCPA
  }

  window.fbq('init', vars.get('FB_PIXEL_ID').toString());
  window.fbq('track', 'PageView'); // can only be called once, then only fires on pushstate event
}
