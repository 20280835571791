const optInComplete = 'signupinitiated';
const qualifiedTune = 'qualifiedTune';

export function gtag(...params) {
  window.gtag?.(...params);
}

export function gaLogOptInComplete(purchasePrice) {
  gtag('event', 'click', {
    event_category: optInComplete,
    event_label: 'label',
    value: purchasePrice,
  });
}

export function gaReportQualifiedTune() {
  gtag('event', qualifiedTune);
}
