import { isInDiscordIFrame } from '../../common/utils/discord';
import { isDevBuild } from '../../common/utils/environment';
import vars from '../../common/vars';

if (!isDevBuild()) {
  // eslint-disable-next-line camelcase, no-undef
  __webpack_public_path__ = isInDiscordIFrame()
    ? vars.get('DISCORD_ASSET_LOC')
    : vars.get('ASSET_LOC');
}
