/*
 * This is the initialization function for Google Adwords, see docs below:
 * https://support.google.com/adwords/answer/6331304
 */

import vars from '../../../common/vars';

const G_ADWORDS_CONVERSION_FORMAT = '3';

export default function initGoogleReportConversion() {
  /* eslint-disable */
  window.goog_snippet_vars = function () {
    var w = window;
    w.google_conversion_id = vars.get('G_ADWORDS_CONVERSION_ID');
    w.google_conversion_label = vars.get('G_ADWORDS_CONVERSION_LABEL');
    w.google_remarketing_only = false;
  };

  window.goog_report_conversion = function (url) {
    goog_snippet_vars();
    window.google_conversion_format = G_ADWORDS_CONVERSION_FORMAT;
    var opt = new Object();
    opt.onload_callback = function () {
      if (typeof url != 'undefined') {
        window.location = url;
      }
    };
    var conv_handler = window['google_trackConversion'];
    if (typeof conv_handler == 'function') {
      conv_handler(opt);
    }
  };
  /* eslint-enable */
}
