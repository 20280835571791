import vars from '../../../common/vars';

// https://drive.google.com/drive/folders/13avx2bFNPER4L_Eg-qkPY6Zy5iuRLTKo?usp=sharing
export default function initComscore(isOptedOutOfPerformance) {
  const comscorePublisherId = vars.get('COMSCORE_PUBLISHER_ID');

  /* eslint-disable */
  global._comscore = global._comscore || [];
  global._comscore.push({
    c1: '2',
    c2: comscorePublisherId,
    cs_ucfr: isOptedOutOfPerformance ? '0' : '1',
  });
  (function () {
    const s = document.createElement('script');
    const el = document.getElementsByTagName('script')[0];
    s.async = true;
    s.src = `https://sb.scorecardresearch.com/cs/${comscorePublisherId}/beacon.js`;
    el.parentNode.insertBefore(s, el);
  })();
}
