// Refer to https://jira.tunein.com/browse/TUNE-8660 for the ticket for this bit.
// The ticket itself contains the vendor code, which has been split between here and
// `src/client/utils/ampBingTracking.js`
import {
  ampBingLoadNameSpace,
  doAsyncCallback,
} from '../ampBing/ampBingTracking';

export default function loadAmpBingTracking() {
  /* eslint-disable */
  (function (w, d, t, r, u) {
    w[u] = w[u] || [];

    const n = d.createElement(t);
    n.src = r;
    n.async = 1;
    n.onload = n.onreadystatechange = function () {
      const rs = this.readyState;
      if (rs && rs !== 'complete' && rs !== 'loaded') {
        return;
      }

      doAsyncCallback();
    };
    const i = d.getElementsByTagName(t)[0];
    i.parentNode.insertBefore(n, i);
  })(
    window,
    document,
    'script',
    'https://bat.bing.com/bat.js',
    ampBingLoadNameSpace,
  );
  /* eslint-enable */
}
