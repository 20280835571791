import vars from '../../../common/vars';

const preInitDataLayer = [];
let isInitialized = false;

export function createGlobalGTagFunction(propertyId) {
  // GTag requires the `arguments` object in order to work. Please do not pass rest args into the dataLayer arrays.
  function gtag() {
    if (isInitialized) {
      window.dataLayer.push(arguments);
    } else {
      preInitDataLayer.push(arguments);
    }
  }

  gtag('js', new Date());
  gtag('config', propertyId);
  gtag('config', `AW-${vars.get('G_ADWORDS_CONVERSION_ID')}`);
  window.gtag = gtag;
}

export function processGTagEvents() {
  window.gtag_enable_tcf_support = true;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(...preInitDataLayer);
  isInitialized = true;
}
