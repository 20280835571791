import { addScript } from '@tunein/web-utils';
import noop from 'lodash/noop';
import { mintSingleton } from '../../mint';
import localStorage from '../../utils/localStorage';
import { extractLotameInterestsFromGuideItem } from './utils';

const LOTAME_CLIENT_ID = 10306;
const LOTAME_GLOBAL = `lotame_${LOTAME_CLIENT_ID}`;
const LOTAME_AUDIENCE_LIMIT = 40;
const LOTAME_SCRIPT_ID = 'TI_LOTAME_SCRIPT';
const AUDIENCES = 'LOTAME_LIGHTNING_AUDIENCES';
const LOTAME_FETCH_AUDIENCE_ERROR = 'lotame.fetch.audience.error';
const LOTAME_COMMAND_QUEUE_ERROR = 'lotame.command.queue.error';

class Lotame {
  #options;

  get #lotame() {
    return window[LOTAME_GLOBAL];
  }

  set #lotame(lotame) {
    window[LOTAME_GLOBAL] = lotame;
  }

  /**
   * Sets up the required lotame object on the window
   */
  #preConfigureLotame() {
    // https://my.lotame.com/t/g9hxvnw/detailed-reference-guide#lightning-tag-setup
    this.#lotame = {
      // https://my.lotame.com/t/g9hxvnw/detailed-reference-guide#config-object
      config: {
        clientId: LOTAME_CLIENT_ID,
        audienceLocalStorage: AUDIENCES,
        onProfileReady: this.#updateAudiences,
      },

      // probably safe to initialized without this, but better to be safe than sorry
      data: {},

      // `cmd` isn't explicitly documented, but this is a function queue that lotame uses to
      // asynchronously handle calls to "Lightning Tag Methods". LTM doc & examples here:
      // https://my.lotame.com/t/g9hxvnw/detailed-reference-guide#lightning-tag-methods
      cmd: [],
    };
  }

  /**
   * Callback defined on the global lotame object, created by #preConfigureLotame().
   * This callback is invoked:
   *  - internally by Lotame when it's finished building audiences
   *  - in the public init() method without an argument to attempt to load audiences from local storage
   * @param profile
   */
  #updateAudiences = (profile) => {
    const audiences = profile
      ? this.#getAudiences(profile)
      : this.#getCachedAudiences();

    mintSingleton.instance?.updateLotameAudiences(audiences);
  };

  /**
   * The profile object is provided by Lotame and is used to fetch audiences.
   *
   * Executes via the onProfileReady callback on:
   *  - init
   *  - page change
   *  - interests update
   * https://my.lotame.com/t/g9hxvnw/detailed-reference-guide#audience-callback
   *
   * @param profile
   * @returns {string[]}
   */
  #getAudiences(profile) {
    try {
      return profile.getAudiences(LOTAME_AUDIENCE_LIMIT) || [];
    } catch (error) {
      this.#options?.onError({
        message: LOTAME_FETCH_AUDIENCE_ERROR,
        context: { error },
      });
    }

    return [];
  }

  /**
   * Retrieves cached comma-delimited audiences string from local storage and splits
   * it into an array and slices before returning.
   * https://my.lotame.com/t/g9hxvnw/detailed-reference-guide#local-storage-usage
   *
   * @returns {string[]}
   */
  #getCachedAudiences() {
    const audiences = localStorage.get(AUDIENCES);
    return audiences?.split(',')?.slice(0, LOTAME_AUDIENCE_LIMIT) || [];
  }

  /**
   * Invokes Lotame collect method and passes along interests
   * https://my.lotame.com/t/g9hxvnw/detailed-reference-guide#data-object
   * https://my.lotame.com/t/g9hxvnw/detailed-reference-guide#collect
   *
   * @param interests
   */
  #addLotameInterests(interests) {
    this.#lotame.collect(this.#buildDataObject(interests));
  }

  /**
   * Formats Lotame interests
   * https://my.lotame.com/t/g9hxvnw/detailed-reference-guide#data-object
   *
   * @param interests
   * @returns {{behaviors: {int: *}}}
   */
  #buildDataObject(interests) {
    return {
      behaviors: {
        int: interests,
      },
    };
  }

  /**
   * Signals to Lotame that a page change has occurred
   * https://my.lotame.com/t/g9hxvnw/detailed-reference-guide#page
   *
   * @param interests
   */
  #signalPageChange(interests) {
    this.#lotame.page(this.#buildDataObject(interests));
  }

  /**
   * Invokes the Lotame setConsent method
   * @param args
   */
  #setLotameConsent(...args) {
    this.#lotame.setConsent(...args);
  }

  /**
   * Queues Lotame commands
   * @param command
   * @param args
   */
  #queueLotameCommand(command, ...args) {
    try {
      this.#lotame.cmd.push(command.bind(this, ...args));
    } catch (error) {
      this.#options?.onError({
        message: LOTAME_COMMAND_QUEUE_ERROR,
        context: { error },
      });
    }
  }

  init(options) {
    this.#options = options;
    this.#preConfigureLotame();
    this.#updateAudiences();
    addScript({
      id: LOTAME_SCRIPT_ID,
      src: `https://tags.crwdcntrl.net/lt/c/${LOTAME_CLIENT_ID}/lt.min.js`,
      async: true,
    });
  }

  /**
   * Queues a set consent command. Only applies when GDPR is not applicable, as Lotame is TCF-compliant and will
   * interface with the TCF API to obtain GDPR consent data.
   * https://my.lotame.com/t/g9hxvnw/detailed-reference-guide#setconsent
   */
  setConsent(isUserOptedIn) {
    const consents = {
      analytics: isUserOptedIn,
      crossdevice: isUserOptedIn,
      datasharing: isUserOptedIn,
      targeting: isUserOptedIn,
    };

    // using noop, since callback return data isn't actionable for our purposes
    // https://my.lotame.com/t/y4hh3sy/user-consent-guide#callback-data
    this.#queueLotameCommand(
      this.#setLotameConsent,
      noop,
      LOTAME_CLIENT_ID,
      consents,
    );
  }

  /**
   * Queues a page change signal command
   * @param guideItem
   */
  signalPageChange(guideItem) {
    const interests = extractLotameInterestsFromGuideItem(guideItem);
    this.#queueLotameCommand(this.#signalPageChange, interests);
  }

  /**
   * Queues #addLotameInterests call
   * @param guideItem
   */
  addInterests(guideItem) {
    const interests = extractLotameInterestsFromGuideItem(guideItem);
    this.#queueLotameCommand(this.#addLotameInterests, interests);
  }

  /**
   * Fetches audiences from local storage
   * @returns {string[]}
   */
  getAudiences() {
    return this.#getCachedAudiences();
  }
}

export default new Lotame();
