import vars from '../../../common/vars';

const comscoreUrl = '/api/v1/comscore?';

export function comscoreBeacon(isUserOptedOut) {
  // https://drive.google.com/drive/folders/13avx2bFNPER4L_Eg-qkPY6Zy5iuRLTKo?usp=sharing
  if (global.COMSCORE && global.COMSCORE.beacon) {
    global.COMSCORE.beacon({
      c1: '2',
      c2: vars.get('COMSCORE_PUBLISHER_ID'),
      cs_ucfr: isUserOptedOut ? '0' : '1',
    });
  }
}

export function comscoreTrackPageview(isUserOptedOut) {
  const cachebuster = Math.round(new Date().getTime() / 1000);

  fetch(
    comscoreUrl +
      new URLSearchParams({
        cb: cachebuster,
      }),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).catch(() => {});

  comscoreBeacon(isUserOptedOut);
}
