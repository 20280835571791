export function extractLotameInterestsFromNowPlaying(nowPlaying) {
  const genreId = nowPlaying?.classification.genreId;
  const primaryGuideId = nowPlaying?.primaryGuideId;
  const secondaryGuideId = nowPlaying?.secondaryGuideId;

  return [genreId, primaryGuideId, secondaryGuideId].filter(Boolean);
}

export function extractLotameInterestsFromGuideItem(guideItem) {
  if (guideItem?.primaryGuideId) {
    return extractLotameInterestsFromNowPlaying(guideItem);
  }

  const genreId =
    guideItem?.metadata?.properties?.classification?.primaryGenreId;
  const primaryGuideId = guideItem?.guideId;

  return [genreId, primaryGuideId].filter(Boolean);
}
