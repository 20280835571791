// Refer to https://jira.tunein.com/browse/TUNE-8660 for the ticket for this bit.
// The ticket itself contains the vendor code, which has been split between here and
// `src/client/utils/yahooPixelTracking.js`
import {
  yahooPixelLoadNameSpace,
  doAsyncCallback,
} from '../yahoo/yahooPixelTracking';

export default function loadYahooPixelTracking() {
  /* eslint-disable */
  (function (w, d, t, r, u) {
    w[u] = w[u] || [];

    const s = d.createElement(t);
    s.src = r;
    s.async = true;
    s.onload = s.onreadystatechange = function () {
      const rs = this.readyState;
      if (rs && rs !== 'complete' && rs !== 'loaded') {
        return;
      }

      doAsyncCallback();
    };
    const scr = d.getElementsByTagName(t)[0];
    scr.parentNode.insertBefore(s, scr);
  })(
    window,
    document,
    'script',
    'https://s.yimg.com/wi/ytc.js',
    yahooPixelLoadNameSpace,
  );
  /* eslint-enable */
}
